var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "close-on-click-modal": false,
      "title": _vm.receiptId ? '编辑收款' : '创建收款',
      "width": "538px"
    },
    on: {
      "open": _vm.handleOpen
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    staticClass: "edit-receipt",
    attrs: {
      "model": _vm.formData,
      "label-width": "100px",
      "size": "small"
    }
  }, [_c('div', {
    staticClass: "form-cell"
  }, [_c('el-form-item', {
    attrs: {
      "label": "收款金额",
      "prop": "amount",
      "rules": [_vm.$formRules.required(), _vm.$formRules.m0m89p2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入金额"
    },
    model: {
      value: _vm.formData.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "amount", $$v);
      },
      expression: "formData.amount"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("元")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "收款日期",
      "prop": "paymentDate",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "date",
      "placeholder": "选择日期",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.formData.paymentDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "paymentDate", $$v);
      },
      expression: "formData.paymentDate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "收款方式",
      "prop": "paymentType",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    on: {
      "change": _vm.handlePayTypeSelect
    },
    model: {
      value: _vm.formData.paymentType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "paymentType", $$v);
      },
      expression: "formData.paymentType"
    }
  }, _vm._l(_vm.payTypes, function (item) {
    return _c('el-radio-button', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1), [0, 1].includes(_vm.formData.paymentType) ? _c('el-form-item', {
    attrs: {
      "label": _vm.formData.paymentType === 1 ? 'POS机' : '收款银行',
      "prop": "payWayId",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    model: {
      value: _vm.formData.payWayId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "payWayId", $$v);
      },
      expression: "formData.payWayId"
    }
  }, _vm._l(_vm.payOptions, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "value": item.id,
        "label": item.label
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.paymentType === 0 ? _c('el-form-item', {
    attrs: {
      "label": "付款户名",
      "prop": "payer",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.payer,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "payer", $$v);
      },
      expression: "formData.payer"
    }
  })], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "交易单号"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.paymentTransactionNo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "paymentTransactionNo", $$v);
      },
      expression: "formData.paymentTransactionNo"
    }
  })], 1)], 1), _c('div', {
    staticClass: "form-cell"
  }, [_c('el-form-item', {
    attrs: {
      "label": "相关附件"
    }
  }, [_c('f-upload', {
    staticClass: "mt20",
    attrs: {
      "size": "small",
      "default-file-list": _vm.defaultFileList
    },
    on: {
      "on-success": _vm.getUploadedImgs
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "备注说明"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea"
    },
    model: {
      value: _vm.formData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "remark", $$v);
      },
      expression: "formData.remark"
    }
  })], 1)], 1)]), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v("保存")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }