<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    :title="receiptId ? '编辑收款' : '创建收款'"
    width="538px"
    class="f-dialog"
    @open="handleOpen"
  >
    <el-form
      class="edit-receipt"
      :model="formData"
      label-width="100px"
      size="small"
      ref="form"
    >
      <div class="form-cell">
        <el-form-item
          label="收款金额"
          prop="amount"
          :rules="[$formRules.required(), $formRules.m0m89p2]"
        >
          <el-input v-model="formData.amount" placeholder="请输入金额">
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
        <el-form-item
          label="收款日期"
          prop="paymentDate"
          :rules="[$formRules.required()]"
        >
          <el-date-picker
            style="width: 100%"
            v-model="formData.paymentDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="收款方式"
          prop="paymentType"
          :rules="[$formRules.required()]"
        >
          <el-radio-group
            v-model="formData.paymentType"
            @change="handlePayTypeSelect"
          >
            <el-radio-button
              v-for="item of payTypes"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="[0, 1].includes(formData.paymentType)"
          :label="formData.paymentType === 1 ? 'POS机' : '收款银行'"
          prop="payWayId"
          :rules="[$formRules.required()]"
        >
          <el-select style="width: 100%" v-model="formData.payWayId">
            <el-option
              v-for="item of payOptions"
              :key="item.id"
              :value="item.id"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="formData.paymentType === 0"
          label="付款户名"
          prop="payer"
          :rules="[$formRules.required()]"
        >
          <el-input v-model="formData.payer" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="交易单号">
          <el-input
            v-model="formData.paymentTransactionNo"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <el-form-item label="相关附件">
          <f-upload
            class="mt20"
            size="small"
            :default-file-list="defaultFileList"
            @on-success="getUploadedImgs"
          ></f-upload>
        </el-form-item>
        <el-form-item label="备注说明">
          <el-input type="textarea" v-model="formData.remark"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submitForm">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FUpload from "@/components/upload";
import { updateReceipt, financialDetail } from "@/api/signedOrder";
import { getPayOptionsByPayWay } from "@/api/common";
export default {
  components: { FUpload },
  props: ["orderId", "amountType", "receiptId", "success"], //  为收款记录id 有值代表编辑 success 操作成功回调
  data() {
    return {
      formData: {
        amount: "",
        payer: "", //付款户名
        payWayId: null, // 收款POS机或者收款银行id
        payWayName: "", //收款POS机或者收款银行名字【前端用】
        payWayInfo: {}, //收款POS机或者收款银行信息 【前端用】
        paymentDate: this.$time().format("YYYY-MM-DD"),
        paymentTransactionNo: "", // 交易单号
        paymentType: 1, // 支付方式：0: 银行转账 1:POS机刷卡 2:现金支付 3:微信支付 4:支付宝 5:定金
        paymentTypeName: "POS机刷卡", //支付方式名字【前端用】
        remark: "",
        attachList: [] // 附件
      },
      defaultFileList: [],
      payOptions: [], // 收款银行 or 收款POS机
      payTypes: [
        { value: 0, label: "银行转账" },
        { value: 1, label: "POS机刷卡" },
        { value: 2, label: "现金支付" },
        { value: 3, label: "微信支付" },
        { value: 4, label: "支付宝" }
      ]
    };
  },
  methods: {
    getUploadedImgs(fileList) {
      this.formData.attachList = fileList.map(file => ({ fileUrl: file.url }));
    },
    async handleOpen() {
      if (this.receiptId) {
        await this.getReceiptRecordDetail();
      }
      // 如果编辑的时候默认是 银行或者 pos机再去请求相应数据 否则选择收款方式的时候再去获取
      if ([0, 1].includes(this.formData.paymentType)) {
        this.getPayOptionsByPayWay();
      }
    },
    // 确认支付方式选择
    handlePayTypeSelect(value) {
      // 选择银行或者pos机
      if (value === 0 || value === 1) {
        this.formData.payWayId = "";
        this.formData.payWayName = "";
        this.getPayOptionsByPayWay();
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    // 获取银行或者pos机列表
    async getPayOptionsByPayWay() {
      const res = await getPayOptionsByPayWay({
        payMethod: this.formData.paymentType,
        estateId: this.estateId
      });
      if (res) {
        this.payOptions = res.map(item => {
          item.label = `${
            item.payMethod === 1
              ? item.name + "，" + "终端号" + item.clientNo + ""
              : item.name
          }`;
          item.description = `${item.name}，${
            item.payMethod === 1 ? item.mchName + "，" : ""
          }${item.mchNo}，${item.mchThridName}`;
          return item;
        });
      }
    },
    // 获取编辑的回款记录详情 【编辑时只会编辑单条】
    async getReceiptRecordDetail() {
      const res = await financialDetail({
        paymentOrderId: this.receiptId
      });
      if (res) {
        this.estateId = res.estateId;
        Object.assign(this.formData, res, {
          attachList: res.attachList || [],
          amount: res.totalAmount,
          payWayId: res.payWayInfo ? res.payWayInfo.id : ""
        });
        this.defaultFileList = res.attachList?.map(item => item.fileUrl) || [];
      }
    },
    close() {
      this.$emit("update:visible", false);
    },
    resetForm() {
      this.selectedPlanList = [];
      this.$refs.form.resetFields();
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const res = await updateReceipt({
          orderId: this.orderId,
          settleId: this.receiptId,
          ...this.formData
        });
        if (res) {
          this.$showSuccess("操作成功");
          this.resetForm();
          this.close();
          if (typeof this.success === "function") {
            this.success();
          } else {
            this.$root.$emit("signOrderDetail_refresh", {
              refreshTypes: ["detail", "log", "companyAccount"]
            });
          }
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.edit-receipt {
  .form-cell {
    & + .form-cell {
      border-top: 1px solid $bdColor;
    }
  }
}
</style>
