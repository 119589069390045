<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    :title="settleId ? '编辑结佣' : '创建结佣'"
    width="538px"
    class="f-dialog"
    @open="handleOpen"
    @close="close"
  >
    <el-form
      class="commission-settlement"
      :model="formData"
      label-width="100px"
      size="small"
      ref="form"
    >
      <div class="form-cell order-info" v-if="info.orderNumber">
        <div class="title">签约订单 {{ info.orderNumber }}</div>
        <ul>
          <li>
            <span class="label">房源编号</span>
            <span>{{ info.houseNumber }}</span>
          </li>
          <li>
            <span class="label">渠道信息</span>
            <span>{{ [info.channelName, info.agentName].join("，") }}</span>
          </li>
          <li>
            <span class="label">合计结佣</span>
            <span>￥{{ info.totalMergeCommission | toThousands }}</span>
          </li>
        </ul>
        <div class="summary">
          历史结佣
          <span class="red"
            >¥{{ (info.channelCommissioned || 0) | toThousands }}</span
          >
          ， 剩余结佣
          <strong>¥ {{ info.channelCommissionSurplus | toThousands }}</strong>
        </div>
      </div>
      <div class="form-cell">
        <el-form-item
          label="结佣金额"
          prop="settlementOrderDetailReqVos[0].amount"
          :rules="[$formRules.required()]"
          class="mt20"
        >
          <el-input
            v-model="formData.settlementOrderDetailReqVos[0].amount"
            placeholder="请输入"
          >
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
        <el-form-item
          label="结佣日期"
          :rules="[$formRules.required()]"
          prop="paymentDate"
        >
          <el-date-picker
            style="width: 100%"
            v-model="formData.paymentDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="支付方式"
          prop="settlementOrderDetailReqVos[0].paymentType"
        >
          <el-radio-group
            v-model="formData.settlementOrderDetailReqVos[0].paymentType"
            @change="handlePayTypeSelect"
          >
            <el-radio-button
              v-for="item of payTypes"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="
            [0, 1].includes(formData.settlementOrderDetailReqVos[0].paymentType)
          "
          :label="
            formData.settlementOrderDetailReqVos[0].paymentType === 1
              ? 'POS机'
              : '付款银行'
          "
          prop="settlementOrderDetailReqVos[0].payWayId"
        >
          <el-select
            style="width: 100%"
            v-model="formData.settlementOrderDetailReqVos[0].payWayId"
            :placeholder="
              formData.settlementOrderDetailReqVos[0].paymentType === 1
                ? '请选择POS机'
                : '请选择退款银行'
            "
          >
            <el-option
              v-for="item of payOptions"
              :key="item.id"
              :value="item.id"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="formData.settlementOrderDetailReqVos[0].paymentType === 0"
          label="收款户名"
          prop="payer"
        >
          <el-input
            v-model="formData.payer"
            placeholder="请输入收款方开户名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="交易单号"
          prop="settlementOrderDetailReqVos[0].paymentTransactionNo"
        >
          <el-input
            v-model="
              formData.settlementOrderDetailReqVos[0].paymentTransactionNo
            "
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <el-form-item
          label="相关附件"
          prop="settlementOrderDetailReqVos[0].attachList"
        >
          <f-upload
            class="mt20"
            size="small"
            :default-file-list="defaultFileList"
            @on-success="getUploadedImgs"
          ></f-upload>
        </el-form-item>
        <el-form-item
          prop="settlementOrderDetailReqVos[0].remark"
          label="备注说明"
        >
          <el-input
            type="textarea"
            v-model="formData.settlementOrderDetailReqVos[0].remark"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submitForm">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
const formData = {
  orderId: null, //认购订单id ,
  payee: "客户", // 收款方的名称 ,
  payeeType: 0, //收款方类型 0:客户 1:公司 2：开发商； 注: 退款收款方默认0-客户
  payer: "", //付款方的名称 ,
  payerType: null, //付款方类型 0:客户 1:公司 2：开发商
  transferTo: "", // 退款时使用 1- 定金 2 - 房款 ,
  paymentDate: "",
  refunded: false, // 是否全部退完(用于退款)
  subscriptionOrderNo: "", //认购单号
  totalAmount: 0, // 收总金额
  type: 1, //付款类型 0-收款 1-退款
  settlementOrderDetailReqVos: [
    {
      amount: "", // 支付金额
      payWayId: null, // 收款POS机或者收款银行id
      paymentTransactionNo: "", // 交易单号
      paymentType: "", // 支付方式：0: 银行转账 1:POS机刷卡 2:现金支付 3:微信支付 4:支付宝 5:定金
      remark: "",
      attachList: [] // 附件
    }
  ] // 收款详情
};
import FUpload from "@/components/upload";
import {
  getOrderPaymentCommission,
  commissionSettleInfo,
  createCommissionSettlement,
  updateSettlement
} from "@/api/signedOrder";
import { getPayOptionsByPayWay } from "@/api/common";
export default {
  components: { FUpload },
  props: ["orderId", "id", "settleId", "success", "estateId"], //  为收款记录id 有值代表编辑 success 操作成功回调
  data() {
    var validateAmount = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error("结佣金额不能小于0"));
      } else {
        // 结佣金额≤剩余结佣
        if (value > this.info.channelCommissionSurplus) {
          callback(new Error("结佣金额不能大于剩余结佣"));
        } else {
          callback();
        }
      }
    };
    return {
      rules: {
        amount: { validator: validateAmount, trigger: "change" }
      },
      refundOrderStr: "", // 退款订单，前端用
      hideFetchLoading: false,
      refundActive: "", // 选中的退款单
      formData: {
        payee: "", // 收款方的名称 ,
        payeeType: 0, //收款方类型 0:客户 1:公司 2：开发商； 注: 退款收款方默认0-客户
        payer: "", //付款方的名称 ,
        payerType: null, //付款方类型 0:客户 1:公司 2：开发商
        transferTo: "", // 退款时使用 1- 定金 2 - 房款 ,
        paymentDate: "",
        refunded: false, // 是否全部退完(用于退款)
        subscriptionOrderNo: "", //认购单号
        totalAmount: 0, // 收总金额
        type: 1, //付款类型 0-收款 1-退款
        settlementOrderDetailReqVos: [
          {
            amount: "", // 支付金额
            payWayId: null, // 收款POS机或者收款银行id
            paymentTransactionNo: "", // 交易单号
            paymentType: "", // 支付方式：0: 银行转账 1:POS机刷卡 2:现金支付 3:微信支付 4:支付宝 5:定金
            remark: "",
            attachList: [] // 附件
          }
        ] // 收款详情
      },
      defaultFileList: [],
      payOptions: [], // 收款银行 or 收款POS机
      payTypes: [
        { value: 0, label: "银行转账" },
        { value: 1, label: "POS机刷卡" },
        { value: 2, label: "现金支付" },
        { value: 3, label: "微信支付" },
        { value: 4, label: "支付宝" }
      ],
      refundList: [], // 退款列表
      unRefundAmount: 0, // 剩余退款
      unRefundAmountTotal: 0, // 合计剩余退款
      info: {}
    };
  },
  methods: {
    async handleOpen() {
      if (this.settleId) {
        // 编辑
        // 获取结佣详情
        this.commissionSettleInfo(this.settleId);
        // 如果编辑的时候默认是 银行或者 pos机再去请求相应数据 否则选择收款方式的时候再去获取
        if (
          [0, 1].includes(
            this.formData.settlementOrderDetailReqVos[0].paymentType
          )
        ) {
          this.getPayOptionsByPayWay();
        }
      } else {
        // 创建
        this.formData.orderId = this.id;
        this.formData.paymentDate = this.$time().format("YYYY-MM-DD");
        // 获取结佣订单信息
        this.getOrderPaymentCommission();
      }
    },
    // 获取结佣订单信息
    async getOrderPaymentCommission(id) {
      const res = await getOrderPaymentCommission({ id: this.id });
      this.info = res;
    },
    // 获取结佣详情
    async commissionSettleInfo(id) {
      const res = await commissionSettleInfo({
        id
      });
      if (res) {
        let { settleInfo, commissionInfo } = res;
        this.info = commissionInfo;
        // 历史结佣金额 = 历史结佣金额 - 上一次结佣金额
        this.info.channelCommissioned = this.$calc.numSub(
          this.info.channelCommissioned,
          settleInfo.settlementOrderDetailReqVos[0].amount
        );
        // 剩余结佣金额 = 剩余结佣金额 + 上一次结佣金额
        this.info.channelCommissionSurplus = this.$calc.accAdd(
          this.info.channelCommissionSurplus,
          settleInfo.settlementOrderDetailReqVos[0].amount
        );
        Object.assign(this.formData, settleInfo);
      }
    },
    getUploadedImgs(fileList) {
      this.formData.settlementOrderDetailReqVos[0].attachList = fileList.map(
        file => ({ fileUrl: file.url })
      );
    },
    // 确认支付方式选择
    handlePayTypeSelect(value) {
      // 选择银行或者pos机
      if (value === 0 || value === 1) {
        this.formData.settlementOrderDetailReqVos[0].payWayId = "";
        this.getPayOptionsByPayWay();
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    // 获取银行或者pos机列表
    async getPayOptionsByPayWay() {
      const res = await getPayOptionsByPayWay({
        payMethod: this.formData.settlementOrderDetailReqVos[0].paymentType,
        estateId: this.estateId
      });
      if (res) {
        this.payOptions = res.map(item => {
          item.label = `${
            item.payMethod === 1
              ? item.name + "，" + "终端号" + item.clientNo + ""
              : item.name
          }`;
          item.description = `${item.name}，${
            item.payMethod === 1 ? item.mchName + "，" : ""
          }${item.mchNo}，${item.mchThridName}`;
          return item;
        });
      }
    },
    close() {
      this.resetForm();
      this.$emit("update:visible", false);
    },
    resetForm() {
      this.defaultFileList = [];
      this.formData = this.$deepClone(formData);
      this.$refs.form.resetFields();
    },
    // 提交 or 保存
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;

        this.formData.estateId = this.estateId;
        this.formData.totalAmount = this.formData.settlementOrderDetailReqVos[0].amount;
        console.log(this.formData);
        const fn = this.settleId
          ? updateSettlement
          : createCommissionSettlement;
        const res = await fn(this.formData);
        if (res) {
          this.$showSuccess("操作成功");
          this.resetForm();
          this.close();
          if (typeof this.success === "function") {
            this.success();
          } else {
            this.$root.$emit("signOrderDetail_refresh", {
              refreshTypes: ["detail", "log", "channelAccount"]
            });
          }
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.commission-settlement {
  .form-cell {
    & + .form-cell {
      border-top: 1px solid $bdColor;
      padding-top: 20px;
    }
  }
}
.order-info {
  background-color: rgba(242, 242, 242, 1);
  margin-bottom: 20px;
  padding: 20px 15px;
  .title {
    font-weight: bold;
    color: #333;
  }
  li {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    color: #666;
    .label {
      color: #8a8a8a;
    }
  }
  .summary {
    color: #8a8a8a;
    text-align: right;
    margin-top: 15px;
    strong {
      font-size: 16px;
      color: #333;
    }
  }
}
::v-deep .el-form-item__label {
  color: #999;
}
</style>
