<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    :title="id ? '编辑退款' : '创建退款'"
    width="538px"
    class="f-dialog"
    @open="handleOpen"
    @close="close"
  >
    <el-form
      class="edit-receipt"
      :model="formData"
      label-width="100px"
      size="small"
      ref="form"
    >
      <div class="form-cell">
        <el-form-item
          label="退款订单"
          prop="orderId"
          :rules="[
            { required: true, message: '请选择退款订单', trigger: 'change' }
          ]"
        >
          <el-autocomplete
            v-if="!id"
            ref="elautocomplete"
            style="width:100%"
            prefix-icon="el-icon-search"
            v-model="refundOrderStr"
            :fetch-suggestions="querySearchAsync"
            :hide-loading="hideFetchLoading"
            placeholder="订单编号、房源编号"
            clearable
            :trigger-on-focus="false"
            @clear="clearOrderSelect"
            @select="handleOrderSelect"
          ></el-autocomplete>
          <div v-else class="order-tip">
            订单编号{{ formData.subscriptionOrderNo }}，房源{{
              formData.roomNumber
            }}
          </div>
        </el-form-item>
        <div class="order-list">
          <label class="item" v-for="(item, index) in refundList" :key="index">
            <el-radio
              v-if="refundList.length > 1"
              v-model="refundActive"
              :label="index"
              @change="selectRefund"
            ></el-radio>
            <div class="info">
              <div class="title">
                {{ item.payItemName }}-{{ item.payeeTypeStr }}
              </div>
              <ul>
                <li v-if="item.customerName">
                  <span>客户姓名</span
                  ><span class="num">{{ item.customerName }}</span>
                </li>
                <li>
                  <span>已确认收款</span
                  ><span class="num"
                    >¥{{ item.confirmedAmount | toThousands }}</span
                  >
                </li>
                <li>
                  <span>待确认收款</span
                  ><span class="num"
                    >¥{{ item.unConfirmAmount | toThousands }}</span
                  >
                </li>
              </ul>
              <div class="summary">
                <template v-if="id">不含当前退款，</template>
                历史退款
                <span class="red"
                  >¥{{ item.refundedAmount | toThousands }}</span
                >
                ， 剩余退款
                <strong>¥ {{ item.unRefundAmount | toThousands }}</strong>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="form-cell">
        <el-form-item
          label="退款金额"
          prop="settlementOrderDetailReqVos[0].amount"
          :rules="[$formRules.required(), rules.amount]"
          class="mt20"
        >
          <el-input
            v-model="formData.settlementOrderDetailReqVos[0].amount"
            placeholder="请输入金额"
          >
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
        <el-form-item
          label="退款日期"
          :rules="[$formRules.required()]"
          prop="paymentDate"
        >
          <el-date-picker
            style="width: 100%"
            v-model="formData.paymentDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="支付方式"
          prop="settlementOrderDetailReqVos[0].paymentType"
        >
          <el-radio-group
            v-model="formData.settlementOrderDetailReqVos[0].paymentType"
            @change="handlePayTypeSelect"
          >
            <el-radio-button
              v-for="item of payTypes"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="
            [0, 1].includes(formData.settlementOrderDetailReqVos[0].paymentType)
          "
          :label="
            formData.settlementOrderDetailReqVos[0].paymentType === 1
              ? 'POS机'
              : '付款银行'
          "
          prop="settlementOrderDetailReqVos[0].payWayId"
        >
          <el-select
            style="width: 100%"
            v-model="formData.settlementOrderDetailReqVos[0].payWayId"
            :placeholder="
              formData.settlementOrderDetailReqVos[0].paymentType === 1
                ? '请选择POS机'
                : '请选择退款银行'
            "
          >
            <el-option
              v-for="item of payOptions"
              :key="item.id"
              :value="item.id"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="formData.settlementOrderDetailReqVos[0].paymentType === 0"
          label="收款户名"
          prop="payer"
        >
          <el-input
            v-model="formData.payer"
            placeholder="请输入收款方开户名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="交易单号"
          prop="settlementOrderDetailReqVos[0].paymentTransactionNo"
        >
          <el-input
            v-model="
              formData.settlementOrderDetailReqVos[0].paymentTransactionNo
            "
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <el-form-item
          label="相关附件"
          prop="settlementOrderDetailReqVos[0].attachList"
        >
          <f-upload
            class="mt20"
            size="small"
            :default-file-list="defaultFileList"
            @on-success="getUploadedImgs"
          ></f-upload>
        </el-form-item>
        <el-form-item
          prop="settlementOrderDetailReqVos[0].remark"
          label="备注说明"
        >
          <el-input
            type="textarea"
            v-model="formData.settlementOrderDetailReqVos[0].remark"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <template v-if="!id">
        <el-button
          type="primary"
          :disabled="!refundList.length"
          @click="submitForm"
          v-if="
            $calc.numSub(
              unRefundAmountTotal,
              formData.settlementOrderDetailReqVos[0].amount
            ) > 0
          "
        >
          提交</el-button
        >
        <el-button type="primary" :disabled="!refundList.length" @click="finish"
          >提交，并完成退款</el-button
        >
      </template>
      <el-button
        type="primary"
        :disabled="!refundList.length"
        @click="submitForm"
        v-else
        >保存</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import FUpload from "@/components/upload";
import {
  getSettlementClassList,
  getSettlementClassListInfo,
  createSettlement,
  updateSettlement,
  settlementInfo
} from "@/api/signedOrder";
import { getPayOptionsByPayWay } from "@/api/common";
import { moment } from "@/utils";
const formData = {
  orderId: null, //认购订单id ,
  payee: "客户", // 收款方的名称 ,
  payeeType: 0, //收款方类型 0:客户 1:公司 2：开发商； 注: 退款收款方默认0-客户
  payer: "", //付款方的名称 ,
  payerType: null, //付款方类型 0:客户 1:公司 2：开发商
  payItemId: null, // 款项类别
  paymentDate: moment().format("YYYY-MM-DD"),
  refunded: false, // 是否全部退完(用于退款)
  subscriptionOrderNo: "", //认购单号
  totalAmount: 0, // 收总金额
  type: 1, //付款类型 0-收款 1-退款
  settlementOrderDetailReqVos: [
    {
      amount: "", // 支付金额
      payWayId: null, // 收款POS机或者收款银行id
      paymentTransactionNo: "", // 交易单号
      paymentType: "", // 支付方式：0: 银行转账 1:POS机刷卡 2:现金支付 3:微信支付 4:支付宝 5:定金
      remark: "",
      attachList: [] // 附件
    }
  ] // 收款详情
};
export default {
  components: { FUpload },
  props: ["orderId", "amountType", "id", "success", "estateId", "refundType"], //  为收款记录id 有值代表编辑 success 操作成功回调
  data() {
    var validateAmount = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error("退款金额不能小于0"));
      } else {
        // 退款金额≤剩余退款
        if (value > this.unRefundAmount) {
          callback(new Error("退款金额不能大于剩余退款"));
        } else {
          callback();
        }
      }
    };
    return {
      rules: {
        amount: { validator: validateAmount, trigger: "change" }
      },
      refundOrderStr: "", // 退款订单，前端用
      hideFetchLoading: false,
      refundActive: "", // 选中的退款单
      formData: this.$deepClone(formData),
      defaultFileList: [],
      payOptions: [], // 收款银行 or 收款POS机
      payTypes: [
        { value: 0, label: "银行转账" },
        { value: 1, label: "POS机刷卡" },
        { value: 2, label: "现金支付" },
        { value: 3, label: "微信支付" },
        { value: 4, label: "支付宝" }
      ],
      refundList: [], // 退款列表
      unRefundAmount: 0, // 剩余退款
      unRefundAmountTotal: 0 // 合计剩余退款
    };
  },
  methods: {
    async handleOpen() {
      this.formData.paymentDate = this.$time().format("YYYY-MM-DD");
      if (this.id) {
        this.formData.orderId = this.orderId;
        await this.getSettlementInfo();
      }
      // 如果编辑的时候默认是 银行或者 pos机再去请求相应数据 否则选择收款方式的时候再去获取
      if (
        [0, 1].includes(
          this.formData.settlementOrderDetailReqVos[0].paymentType
        )
      ) {
        this.getPayOptionsByPayWay();
      }
    },
    //编辑时，获取结算（退款）详情
    async getSettlementInfo() {
      const res = await settlementInfo({
        id: this.id
      });
      if (res) {
        const { settlementOrderDetailReqVos } = res;
        Object.assign(this.formData, res);
        // 获取退款列表
        this.getRefundList(
          res.subscriptionOrderNo,
          settlementOrderDetailReqVos[0].amount
        );
        this.defaultFileList =
          res.settlementOrderDetailReqVos[0].attachList?.map(
            item => item.fileUrl
          ) || [];
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        });
      }
    },
    // 查询退款订单
    async querySearchAsync(keywords, cb) {
      this.hideFetchLoading = true;
      if (!keywords || !keywords.trim()) return;
      this.hideFetchLoading = false;
      let query = {
        keywords,
        estateId: this.estateId,
        pageSize: 100
      };
      let res = await getSettlementClassList(query);
      let statusMaps = {
        1: "认购(待签约)",
        5: "签约(回款中)",
        10: "签约(已回款)",
        15: "签约(结佣中)",
        20: "签约(已结佣)",
        25: "退款中",
        30: "已退款",
        100: "已取消"
      };
      let extendStatusMaps = {
        1: "他签",
        2: "认购退款中",
        3: "认购退款取消",
        4: "签约退款中",
        5: "已退款"
      };
      let data = [];
      data = res.list.map(item => {
        let orderStr =
          item.orderStatus == 100
            ? `已取消(${extendStatusMaps[item.extendStatus]})`
            : statusMaps[item.orderStatus];
        return {
          ...item,
          value: `订单${item.orderCode}，房源${item.houseNumber}，${orderStr}`
        };
      });
      this.hideFetchLoading = true;
      cb(data);
      this.$refs.elautocomplete.handleFocus(); // 修复 el-autocomplete 使用clearable属性,点击清除重新输入提示不显示的bug
    },
    // 选择退款订单
    async handleOrderSelect(item) {
      this.formData.orderId = item.orderId;
      this.formData.subscriptionOrderNo = item.orderCode; // 认购单号
      this.getRefundList(item.orderCode);
    },
    // 清空已选择的退款订单
    clearOrderSelect() {
      this.formData.orderId = ""; // 退款订单
      this.formData.subscriptionOrderNo = ""; // 认购单号
      this.refundList = []; // 退款列表
      this.refundActive = ""; // 退款选中项
      this.formData.payerType = ""; // 退款方
      this.unRefundAmount = ""; // 剩余退款
      this.unRefundAmountTotal = ""; //清空合计剩余退款
    },
    // 获取退款列表
    async getRefundList(orderCode, prevAmount) {
      let res = await getSettlementClassListInfo({
        orderCode
      });
      if (res) {
        if (!res.length) {
          this.$showError("暂无可退款项");
          return;
        }
        if (this.id) {
          // 编辑退款，匹配退款方
          let result = res.find(
            item =>
              item.payeeType === this.formData.payerType &&
              item.type === this.refundType
          );
          // 历史退款金额 = 历史退款金额 - 上一次退款金额
          result.refundedAmount = this.$calc.numSub(
            result.refundedAmount,
            prevAmount
          );
          // 剩余退款金额 = 剩余退款金额 + 上一次退款金额
          result.unRefundAmount = this.$calc.accAdd(
            result.unRefundAmount,
            prevAmount
          );
          this.refundList = [result];
        } else {
          // 新建退款，筛选出“已确认收款＞0”的退款方，供选择
          this.refundList = res.filter(
            item => item.confirmedAmount > 0 && item.unRefundAmount >= 0
          );
          // 合计剩余退款，用于判断是否显示【提交】按钮
          this.unRefundAmountTotal = this.refundList.reduce((total, item) => {
            return total + item.unRefundAmount * 1;
          }, 0);
        }
        if (this.refundList.length) {
          // 选中第一项
          this.refundActive = 0;
          this.selectRefund(0);
        }
      }
    },
    // 选择退款方
    selectRefund(index) {
      let item = this.refundList[index];
      this.formData.payItemId = item.payItemId;
      this.formData.payerType = item.payeeType;
      this.unRefundAmount = item.unRefundAmount; // 剩余退款
      if (!this.id) {
        this.formData.settlementOrderDetailReqVos[0].amount =
          item.unRefundAmount; // 退款金额：默认值“剩余退款”
      }
    },
    getUploadedImgs(fileList) {
      this.formData.settlementOrderDetailReqVos[0].attachList = fileList.map(
        file => ({ fileUrl: file.url })
      );
    },
    // 确认支付方式选择
    handlePayTypeSelect(value) {
      // 选择银行或者pos机
      if (value === 0 || value === 1) {
        this.formData.settlementOrderDetailReqVos[0].payWayId = "";
        this.getPayOptionsByPayWay();
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    // 获取银行或者pos机列表
    async getPayOptionsByPayWay() {
      const res = await getPayOptionsByPayWay({
        payMethod: this.formData.settlementOrderDetailReqVos[0].paymentType,
        estateId: this.estateId
      });
      if (res) {
        this.payOptions = res.map(item => {
          item.label = `${
            item.payMethod === 1
              ? item.name + "，" + "终端号" + item.clientNo + ""
              : item.name
          }`;
          item.description = `${item.name}，${
            item.payMethod === 1 ? item.mchName + "，" : ""
          }${item.mchNo}，${item.mchThridName}`;
          return item;
        });
      }
    },
    close() {
      this.resetForm();
      this.$emit("update:visible", false);
    },
    resetForm() {
      this.refundOrderStr = "";
      this.refundList = []; // 退款列表
      this.refundActive = ""; // 退款选中项
      this.unRefundAmount = ""; // 剩余退款
      this.unRefundAmountTotal = ""; //清空合计剩余退款
      this.defaultFileList = [];
      this.formData = this.$deepClone(formData);
      this.$refs.form.resetFields();
    },
    // 创建 or 编辑退款
    async editSettlement() {
      this.formData.estateId = this.estateId;
      this.formData.totalAmount = this.formData.settlementOrderDetailReqVos[0].amount;
      const fn = this.id ? updateSettlement : createSettlement;
      const res = await fn(this.formData);
      if (res) {
        this.$showSuccess("操作成功");
        this.resetForm();
        this.close();
        if (typeof this.success === "function") {
          this.success();
        } else {
          this.$root.$emit("signOrderDetail_refresh", {
            refreshTypes: ["detail", "log", "companyAccount"]
          });
        }
      }
    },
    // 提交 or 保存
    submitForm() {
      this.formData.refunded = false; // 是否全部退完(用于退款)
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        this.editSettlement();
      });
    },
    // 提交，并完成退款
    finish() {
      this.formData.refunded = true; // 是否全部退完(用于退款)
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        this.$showConfirm(
          async () => {
            this.$refs.form.validate(async valid => {
              if (!valid) return;
              this.editSettlement();
            });
          },
          "完成退款后，将取消订单，并释放房源！",
          "提交，并完成退款"
        );
      });
    }
  }
};
</script>

<style scoped lang="scss">
.edit-receipt {
  .form-cell {
    & + .form-cell {
      border-top: 1px solid $bdColor;
    }
  }
}
.order-list {
  padding: 0 0 20px 30px;
  margin-top: -8px;
  .item {
    display: flex;
    background-color: #f2f2f2;
    padding: 20px;
    .info {
      flex: 1;
    }
    .title {
      color: #333;
      font-weight: bold;
      line-height: 100%;
      margin-bottom: 10px;
    }
    li {
      @include flex-ycb();
      margin-top: 10px;
      cursor: pointer;
      span {
        color: #999;
      }
      .num {
        color: #333;
      }
    }
    .summary {
      margin-top: 10px;
      color: #999;
      text-align: right;
      strong {
        color: #333;
      }
    }
  }
  .item + .item {
    margin-top: 10px;
  }
  ::v-deep .el-radio__label {
    display: none;
  }
  .el-radio {
    margin-right: 10px;
  }
}
.order-tip {
  text-align: right;
  width: 100%;
  color: #000;
}
::v-deep .el-form-item__label {
  color: #999;
}
</style>
