<template>
  <el-drawer
    v-bind="$attrs"
    v-on="$listeners"
    :modal="false"
    :with-header="false"
    append-to-body
    size="375"
    class="financial-detail-drawer"
    @open="openDrawer"
  >
    <div
      class="financial-detail"
      :class="{ grey: detail.orderType == 1 && detail.transfered }"
    >
      <div class="top-bar">
        <div class="btn" @click="closeDrawer">
          <i class="el-icon-arrow-right"></i>
        </div>
        {{ detail.type == 1 ? "退" : "收" }}款单详情
      </div>
      <div class="financial-header">
        <div class="title">{{ detail.type == 1 ? "退" : "收" }}款金额</div>
        <div class="amount">
          ¥<strong>{{ (detail.totalAmount || 0) | toThousands }}</strong>
        </div>
        <div class="status">
          <template v-if="detail.type == 0">
            <span v-if="detail.orderType == 1 && detail.transfered"
              >转入房款</span
            >
            <template v-else>
              <span class="red" v-if="detail.orderState == 0">待确认</span>
              <span class="green" v-if="detail.orderState == 1">已确认</span>
            </template>
          </template>
          <template v-else>
            <span class="red">退款成功</span>
          </template>
        </div>
        <ul>
          <li>
            <span class="label">
              {{ detail.type == 1 ? "支付" : "收款" }}方式
            </span>
            <span>{{ detail.paymentTypeName || "-" }}</span>
          </li>
          <li>
            <span class="label">
              {{ detail.type == 1 ? "退款" : "回款" }}日期
            </span>
            <span>{{ detail.paymentDate }}</span>
          </li>
          <li v-if="detail.type == 0">
            <span class="label">回款计划</span>
            <span>{{
              [
                detail.payItemName,
                detail.totalPeriod > 1
                  ? detail.period + `/共${detail.totalPeriod}期`
                  : ""
              ] | link("，")
            }}</span>
          </li>
          <li v-if="detail.type === 0">
            <span class="label">
              {{ detail.type == 1 ? "退款方" : "收款方" }}
            </span>
            <span>{{ detail.payeeName }}</span>
          </li>
          <li v-if="detail.type == 1">
            <span class="label">退款类型</span>
            <span>
              {{ detail.orderType === 1 ? "定金" : "房款" }}，{{
                detail.payerType == 1 ? "我司" : "甲方"
              }}
            </span>
          </li>
          <li>
            <span class="label">认购签约</span>
            <span>
              {{ detail.estateName || "" }}，{{ detail.houseNumber || "" }}
            </span>
          </li>
        </ul>
      </div>
      <div class="financial-box">
        <div class="financial-cell financial-about">
          <!-- 0: 银行转账 1:POS机刷卡 2:现金支付 3:微信支付 4:支付宝 5:定金  -->
          <ul class="list">
            <template v-if="detail.paymentType === 1">
              <li>
                <span class="label">POS机</span>
                <span v-if="detail.payWayInfo && detail.payWayInfo.name">
                  {{ detail.payWayInfo.name }}
                </span>
                <span v-else>-</span>
              </li>
              <li>
                <span class="label">商户名</span>
                <span v-if="detail.payWayInfo && detail.payWayInfo.mchName">
                  {{ detail.payWayInfo.mchName }}
                </span>
                <span v-else>-</span>
              </li>
              <li>
                <span class="label">商户号</span>
                <span v-if="detail.payWayInfo && detail.payWayInfo.mchNo">
                  {{ detail.payWayInfo.mchNo }}
                </span>
                <span v-else>-</span>
              </li>
              <li>
                <span class="label">终端号</span>
                <span v-if="detail.payWayInfo && detail.payWayInfo.clientNo">
                  {{ detail.payWayInfo.clientNo }}
                </span>
                <span v-else>-</span>
              </li>
              <li>
                <span class="label">发卡行</span>
                <span
                  v-if="detail.payWayInfo && detail.payWayInfo.mchThridName"
                >
                  {{ detail.payWayInfo.mchThridName }}
                </span>
                <span v-else>-</span>
              </li>
            </template>
            <template v-if="detail.paymentType === 0">
              <li>
                <span class="label">
                  {{ detail.type == 1 ? "收" : "付" }}款户名
                </span>
                <span>
                  {{ detail.payer || "-" }}
                </span>
              </li>
              <li>
                <span class="label">
                  {{ detail.type == 1 ? "退" : "收" }}款银行
                </span>
                <span v-if="detail.payWayInfo && detail.payWayInfo.name">
                  {{ detail.payWayInfo.name }}
                </span>
                <span v-else>-</span>
              </li>
              <li>
                <span class="label">开户名</span>
                <span v-if="detail.payWayInfo && detail.payWayInfo.mchName">
                  {{ detail.payWayInfo.mchName }}
                </span>
                <span v-else>-</span>
              </li>
              <li>
                <span class="label">开户行</span>
                <span
                  v-if="detail.payWayInfo && detail.payWayInfo.mchThridName"
                >
                  {{ detail.payWayInfo.mchThridName }}
                </span>
                <span v-else>-</span>
              </li>
              <li>
                <span class="label">卡号</span>
                <span v-if="detail.payWayInfo && detail.payWayInfo.mchNo">{{
                  detail.payWayInfo.mchNo | cardNumber
                }}</span>
                <span v-else>-</span>
              </li>
            </template>
            <li v-if="detail.paymentType !== 5">
              <span class="label">交易单号</span>
              <span>{{ detail.paymentTransactionNo || "-" }}</span>
            </li>
            <li>
              <span class="label">
                {{ detail.type == 1 ? "退" : "收" }}款单号
              </span>
              <span>{{ detail.receiptNo || "-" }}</span>
            </li>
            <li>
              <span class="label">创建人</span>
              <span>{{ detail.inUser || "-" }}</span>
            </li>
            <li>
              <span class="label">创建时间</span>
              <span>{{ detail.inDate || "-" }}</span>
            </li>
          </ul>
          <dl v-if="detail.remark">
            <dt>备注说明</dt>
            <dd class="remark">
              {{ detail.remark }}
            </dd>
          </dl>
          <dl
            class="attachment"
            v-if="detail.attachList && detail.attachList.length"
          >
            <dt>相关附件</dt>
            <dd>
              <el-image
                style="width: 77px; height: 74px"
                v-for="file of detail.attachList"
                :key="file.fileUrl"
                :src="file.fileUrl"
                :preview-src-list="detail.attachList.map(item => item.fileUrl)"
              ></el-image>
            </dd>
          </dl>
        </div>
        <div class="financial-cell  mt10">
          <ul class="link-list">
            <li @click="toOrderDetail" v-if="!isDetailPage">
              <span>查看认购签约单</span>
              <i class="el-icon-arrow-right"></i>
            </li>
            <template v-if="detail.depositInfo && detail.depositInfo.length">
              <li
                v-for="(deposit, index) of detail.depositInfo"
                :key="index"
                @click="getFinancialDetail(deposit.id)"
              >
                <span
                  >查看定金{{ index + 1 }} （转入金额 ¥{{
                    deposit.value | toThousands
                  }}）</span
                >
                <i class="el-icon-arrow-right"></i>
              </li>
            </template>
          </ul>
        </div>
        <div class="financial-cell mt10">
          <ul class="action">
            <li @click="action('sure')" v-if="detail.orderState == 0">
              确认收款
            </li>
            <li
              @click="action('edit')"
              v-if="!(detail.orderType == 1 && detail.transfered)"
            >
              重新编辑
            </li>
            <li
              class="delete"
              @click="action('del')"
              v-if="!(detail.orderType == 1 && detail.transfered)"
            >
              删除{{ detail.type == 1 ? "退款" : "收款" }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { financialDetail } from "@/api/signedOrder";
export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    orderId: {
      type: Number,
      default: null
    },
    estateId: {
      type: Number,
      default: null
    },
    isDetailPage: {
      // 订单详情页，隐藏“查看认购签约单链接”
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      detail: {},
      isFirst: true // 标记是否已经请求过接口
    };
  },
  watch: {
    id: {
      handler(id) {
        if (this.$attrs.visible) {
          this.getFinancialDetail(id);
          this.isFirst = false; // 如果在id变化时，已经请求过，打开抽屉时，就不用再次请求
        }
      }
    }
  },
  methods: {
    openDrawer() {
      // 如果先点过“编辑”, 再查看“收款单详情”，id不会变，不会触发请求，所以需要在打开drawer时请求一次
      if (this.isFirst) {
        this.getFinancialDetail(this.id);
      }
    },
    closeDrawer() {
      this.$emit("update:visible", false);
      this.isFirst = true;
    },
    action(type) {
      this.$emit(type);
    },
    // 获取收款详情
    async getFinancialDetail(id) {
      const res = await financialDetail({
        paymentOrderId: id,
        showLoading: true
      });
      if (res) {
        this.detail = res;
      }
    },
    // 进入订单详情
    toOrderDetail() {
      // 打开新浏览器tab
      this.$openWindow(`/distribution/appManage/signedOrder/${this.orderId}`);
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  background-color: $bdColor;
}
.financial-detail-drawer {
  left: auto;
  ::v-deep .el-drawer__container {
    width: 450px;
  }
}
.financial-detail {
  width: 375px;
  background-color: $bdColor;
  .top-bar {
    @include flex-xyc;
    position: absolute;
    width: 100%;
    height: 44px;
    background-color: #0a0f30;
    font-size: 13px;
    color: #fff;
    .btn {
      @include flex-xyc;
      width: 44px;
      height: 44px;
      background-color: $red;
      position: absolute;
      left: 0;
      top: 0;
      i {
        display: block;
        @include flex-xyc;
        font-size: 18px;
        width: 24px;
        height: 24px;
        border: 1px solid #fff;
        border-radius: 50%;
        cursor: pointer;
        &:active {
          opacity: 0.75;
        }
      }
    }
  }
}
.financial-box {
  padding: 10px;
}
.financial-header {
  background-color: #fff;
  padding: 70px 20px 25px 20px;
  .title,
  .status {
    color: $color9;
    text-align: center;
  }
  .status {
    margin-top: 10px;
  }
  .amount {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    strong {
      font-size: 28px;
      margin-left: 5px;
    }
  }
  ul {
    margin-top: 35px;
  }
  li {
    font-size: 14px;
    color: $color3;
    .label {
      display: inline-block;
      min-width: 60px;
      color: $color9;
      margin-right: 20px;
    }
  }
  li + li {
    margin-top: 8px;
  }
}
.financial-cell {
  background-color: #fff;
  border-radius: 5px;
}
.financial-about {
  padding: 15px;
  dl {
    padding-top: 10px;
    border-top: 1px solid #f5f5f5;
    margin-top: 15px;
  }
  dt {
    color: $color9;
  }
  .remark {
    color: $color3;
    line-height: 26px;
    margin-top: 15px;
  }
  .attachment {
    .el-image {
      border-radius: 2px;
      margin: 15px 10px 0 0;
    }
  }
}
.list {
  li {
    @include flex-ycb;
    font-size: 14px;
    color: $color3;
    .label {
      color: $color9;
    }
  }
  li + li {
    margin-top: 8px;
  }
}
.link-list {
  li {
    @include flex-ycb;
    height: 50px;
    padding: 0 20px;
    cursor: pointer;
    color: $color3;
    i {
      font-size: 16px;
      color: $color9;
    }
    &:active {
      opacity: 0.75;
    }
  }
  li + li {
    border-top: 1px solid #f5f5f5;
  }
}
.action {
  @include flex-yc;
  justify-content: space-around;
  padding: 0 20px;
  li {
    padding: 16px 20px;
    font-size: 14px;
    color: $primary;
    cursor: pointer;
    &.delete {
      color: $red;
    }
    &:active {
      opacity: 0.8;
    }
  }
}
.grey {
  .financial-header {
    .amount,
    li {
      color: $color9;
    }
  }
  .list li {
    color: $color9;
  }
  .financial-about .remark {
    color: $color9;
  }
  .link-list li {
    color: $color9;
  }
}
</style>
