import request from "@/utils/request";

// 回款计划列表
export const orderPlanList = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/plan/list`,
    data,
    method: "post"
  });

// 回款计划列表统计
export const orderPlanListCount = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/plan/list/count`,
    data,
    method: "post"
  });

// 【回款计划】列表顶部统计
export const paymentPlanTopCount = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/plan/top/count`,
    data,
    method: "post"
  });

// 【财务对账】列表顶部统计
export const paymentFinancialTopCount = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/financial/top/count`,
    data,
    method: "post"
  });

// 职业顾问下拉列表
export const customerAuth = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/customer/auth`,
    data,
    method: "post"
  });

// 财务对账列表
export const financialList = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/financial/list`,
    data,
    method: "post"
  });

// 财务对账列表统计
export const financialListCount = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/financial/count`,
    data,
    method: "post"
  });

// 【财务汇总】列表
export const financialSummary = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/financial/summary`,
    data,
    method: "post"
  });

// 【财务汇总】列表
export const financialSummarySum = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/financial/summary/sum`,
    data,
    method: "post"
  });

// 财务对账列表删除
export const financialDel = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/financial/del`,
    params,
    method: "post"
  });

// 财务对账列表确认
export const financialConfirm = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/financial/confirm`,
    params,
    method: "post"
  });

// 认购签约订单详情
export const getSignOrderDetail = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/info/${data.id}`,
    data,
    method: "post"
  });

// 认购/签约 列表
export const getOrderList = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/list`,
    data,
    method: "post"
  });

// 认购/签约 列表 汇总
export const getOrderListSum = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/list/sum`,
    data,
    method: "post"
  });

// 认购/签约 列表 tabCount
export const getOrderListTabCount = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/list/tab/count`,
    data,
    method: "post"
  });

// 认购列表-渠道-下拉项获取
export const getOrderConditionChannel = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/condition/channel`,
    params,
    method: "get"
  });

// 认购列表-置业顾问-下拉项获取
export const getOrderConditionCustomerauth = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/condition/customerauth`,
    params,
    method: "get"
  });

// 创建收款
export const createReceipt = (orderId, data) =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/${orderId}`,
    data
  });

// 删除收款
export const deleteReceipt = params =>
  request({
    url: `api/v1/platform/distribution/web/estate/order/collection/del`,
    params
  });

// 删除订单
export const deleteOrder = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/delete/${params.orderId}`,
    params
  });

// 创建签约
export const createSign = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/sign/${data.orderId}`,
    data
  });

// 查询置业顾问列表
export const getConsultantList = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/auth/list`,
    params,
    method: "GET"
  });

// 申请结佣
export const createCommissionPayment = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/commission/add`,
    data
  });

// 编辑结佣
export const updateCommissionPayment = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/commission/edit`,
    data
  });

// 确认结佣
export const confirmCommissionPayment = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/commission/confirm`,
    data
  });

// 查询订单佣金信息
export const getOrderCommissionDetail = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/commission/detail`,
    params,
    method: "GET"
  });

// 删除佣金
export const deleteCommissionPayment = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/commission/del`,
    params
  });

// 查询订单收款信息
export const getOrderReceiptDetail = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/collection/info`,
    params
  });

// 删除附件
export const deleteAttaches = (orderId, params) =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/delete/attachs/${orderId}`,
    params
  });

// 添加附件
export const addAttaches = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/add/attachs/${data.orderId}`,
    data
  });

// 删除备注
export const deleteRemark = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/remark/delete/${data.id}`,
    data
  });

// 添加备注
export const addRemark = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/remark/${data.orderId}`,
    data
  });

// 创建收款时-回款计划选择
export const getOrderReceiptPlanList = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/plan/list/${params.orderId}`,
    params,
    method: "GET"
  });

// 【报备信息】创建佣金时
export const getOrderReportList = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/report`,
    params
  });

// 获取订单几个操作记录列表
export const getOrderLogsList = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/logs`,
    params,
    method: "GET"
  });

// 编辑收款记录
export const updateReceipt = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/${data.orderId}/${data.settleId}`,
    data
  });

// 获取收款记录详情
export const getReceiptRecordDetail = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/${params.orderId}/${params.settleId}/info`,
    params,
    method: "GET"
  });

// 删除客户
export const deleteCustomer = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/delete/customer/${params.orderId}/${params.id}`,
    params
  });

// 添加和编辑客户
export const editCustomer = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/update/customer/${data.orderId}`,
    data
  });

// 编辑房源
export const editHouse = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/update/house/${data.orderId}`,
    data
  });

// 编辑认购
export const editSubscription = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/update/${data.orderId}`,
    data
  });

// 编辑签约
export const editSign = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/update/sign/${data.orderId}`,
    data
  });

// 编辑回款计划时的回款计划列表
export const getReceiptPlanList = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/plan/list/${data.orderId}/all`,
    method: "GET",
    data
  });

// 编辑回款计划
export const editReceiptPlan = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/plan/update/${data.orderId}`,
    data
  });

// 收款记录明细详情
export const financialDetail = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/financial/detail`,
    params,
    data: params
  });

// 收款记录明细详情
export const paymentType = estateId =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/type/cascade?estateId=${estateId}`,
    method: "get"
  });

// 渠道结佣列表
export const getChannelPaymentList = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/commission/list`,
    data
  });

// 批量删除结佣
export const delChannelPaymentBatch = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/commission/del/batch`,
    params
  });

// 批量确认结佣
export const confirmChannelPaymentBatch = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/commission/confirm/batch`,
    params
  });

// 渠道结佣统计
export const getChannelPaymentCounts = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/commission/count`,
    data
  });

// 获取营销活动列表
export const getMarketingList = data =>
  request({
    url: `/api/v1/platform/distribution/web/marketingActivity/list`,
    data
  });

// 获取营销活动列表统计
export const getMarketingCounts = data =>
  request({
    url: `/api/v1/platform/distribution/web/marketingActivity/listCount`,
    data
  });

// 渠道结佣-渠道-下拉项获取
export const geCommissionChannelList = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/commission/channel`,
    params,
    method: "get"
  });

// 利润核算
export const getProfitCalcList = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/profit/list`,
    data
  });

// 利润核算统计
export const getProfitCalcCounts = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/profit/sum`,
    data
  });

// 获取他签订单的已收金额
export const getOtherSignAmountList = params =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/other/sign/list/${params.orderId}`,
    params,
    method: "GET"
  });

// 创建退款查询可退的定金 房款信息列表
export const getSettlementClassList = data =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/classList`,
    data
  });

// 创建退款查询可退的定金 房款信息列表 的详细
export const getSettlementClassListInfo = data => {
  return request({
    url: `/api/v1/platform/distribution/web/settlement/classListInfo/${data.orderCode}`
  });
};

// 创建结算付款订单/创建退款订单
export const createSettlement = data =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/create`,
    data
  });

// 编辑付款订单/编辑退款订单
export const updateSettlement = data =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/update`,
    data
  });

// 结算详情
export const settlementInfo = data =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/info/${data.id}`,
    data
  });

// 退款单子删除
export const deleteSettlementRefund = data =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/refund/delete/${data.id}`,
    data
  });

// 佣金结算订单
export const createCommissionSettlement = data =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/commission/create`,
    data
  });

// 佣金结算订单
export const getOrderPaymentCommission = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/commission/${data.id}/info`,
    data,
    method: "GET"
  });

// 佣金结算订单
export const commissionSettleList = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/commission/settle/list`,
    data
  });

// 渠道结佣编辑信息获取
export const commissionSettleInfo = data =>
  request({
    url: `/api/v1/platform/distribution/web/estate/order/payment/commission/settle/${data.id}/info`,
    data,
    method: "GET"
  });

// 删除佣金结算订单
export const deleteSettlementCommission = data =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/commission/delete/${data.id}`,
    data
  });

//查询收款人或收款类别
export const getPayeeList = params =>
  request({
    url: `api/v1/platform/web/payway/list/common`,
    params
  });
