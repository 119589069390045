var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "close-on-click-modal": false,
      "title": _vm.settleId ? '编辑结佣' : '创建结佣',
      "width": "538px"
    },
    on: {
      "open": _vm.handleOpen,
      "close": _vm.close
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    staticClass: "commission-settlement",
    attrs: {
      "model": _vm.formData,
      "label-width": "100px",
      "size": "small"
    }
  }, [_vm.info.orderNumber ? _c('div', {
    staticClass: "form-cell order-info"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("签约订单 " + _vm._s(_vm.info.orderNumber))]), _c('ul', [_c('li', [_c('span', {
    staticClass: "label"
  }, [_vm._v("房源编号")]), _c('span', [_vm._v(_vm._s(_vm.info.houseNumber))])]), _c('li', [_c('span', {
    staticClass: "label"
  }, [_vm._v("渠道信息")]), _c('span', [_vm._v(_vm._s([_vm.info.channelName, _vm.info.agentName].join("，")))])]), _c('li', [_c('span', {
    staticClass: "label"
  }, [_vm._v("合计结佣")]), _c('span', [_vm._v("￥" + _vm._s(_vm._f("toThousands")(_vm.info.totalMergeCommission)))])])]), _c('div', {
    staticClass: "summary"
  }, [_vm._v(" 历史结佣 "), _c('span', {
    staticClass: "red"
  }, [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.info.channelCommissioned || 0)))]), _vm._v(" ， 剩余结佣 "), _c('strong', [_vm._v("¥ " + _vm._s(_vm._f("toThousands")(_vm.info.channelCommissionSurplus)))])])]) : _vm._e(), _c('div', {
    staticClass: "form-cell"
  }, [_c('el-form-item', {
    staticClass: "mt20",
    attrs: {
      "label": "结佣金额",
      "prop": "settlementOrderDetailReqVos[0].amount",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.settlementOrderDetailReqVos[0].amount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.settlementOrderDetailReqVos[0], "amount", $$v);
      },
      expression: "formData.settlementOrderDetailReqVos[0].amount"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("元")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "结佣日期",
      "rules": [_vm.$formRules.required()],
      "prop": "paymentDate"
    }
  }, [_c('el-date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "date",
      "placeholder": "选择日期",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.formData.paymentDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "paymentDate", $$v);
      },
      expression: "formData.paymentDate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "支付方式",
      "prop": "settlementOrderDetailReqVos[0].paymentType"
    }
  }, [_c('el-radio-group', {
    on: {
      "change": _vm.handlePayTypeSelect
    },
    model: {
      value: _vm.formData.settlementOrderDetailReqVos[0].paymentType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.settlementOrderDetailReqVos[0], "paymentType", $$v);
      },
      expression: "formData.settlementOrderDetailReqVos[0].paymentType"
    }
  }, _vm._l(_vm.payTypes, function (item) {
    return _c('el-radio-button', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1), [0, 1].includes(_vm.formData.settlementOrderDetailReqVos[0].paymentType) ? _c('el-form-item', {
    attrs: {
      "label": _vm.formData.settlementOrderDetailReqVos[0].paymentType === 1 ? 'POS机' : '付款银行',
      "prop": "settlementOrderDetailReqVos[0].payWayId"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": _vm.formData.settlementOrderDetailReqVos[0].paymentType === 1 ? '请选择POS机' : '请选择退款银行'
    },
    model: {
      value: _vm.formData.settlementOrderDetailReqVos[0].payWayId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.settlementOrderDetailReqVos[0], "payWayId", $$v);
      },
      expression: "formData.settlementOrderDetailReqVos[0].payWayId"
    }
  }, _vm._l(_vm.payOptions, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "value": item.id,
        "label": item.label
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.settlementOrderDetailReqVos[0].paymentType === 0 ? _c('el-form-item', {
    attrs: {
      "label": "收款户名",
      "prop": "payer"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入收款方开户名"
    },
    model: {
      value: _vm.formData.payer,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "payer", $$v);
      },
      expression: "formData.payer"
    }
  })], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "交易单号",
      "prop": "settlementOrderDetailReqVos[0].paymentTransactionNo"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.settlementOrderDetailReqVos[0].paymentTransactionNo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.settlementOrderDetailReqVos[0], "paymentTransactionNo", $$v);
      },
      expression: "\n            formData.settlementOrderDetailReqVos[0].paymentTransactionNo\n          "
    }
  })], 1)], 1), _c('div', {
    staticClass: "form-cell"
  }, [_c('el-form-item', {
    attrs: {
      "label": "相关附件",
      "prop": "settlementOrderDetailReqVos[0].attachList"
    }
  }, [_c('f-upload', {
    staticClass: "mt20",
    attrs: {
      "size": "small",
      "default-file-list": _vm.defaultFileList
    },
    on: {
      "on-success": _vm.getUploadedImgs
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "settlementOrderDetailReqVos[0].remark",
      "label": "备注说明"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea"
    },
    model: {
      value: _vm.formData.settlementOrderDetailReqVos[0].remark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.settlementOrderDetailReqVos[0], "remark", $$v);
      },
      expression: "formData.settlementOrderDetailReqVos[0].remark"
    }
  })], 1)], 1)]), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v("提交")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }